<template>
  <div class="main-container" id="printArea">
    <div class="header">
      <img src="@/assets/images/IA/paper_img_logo.png" alt="koROM" style="width: 174px"/>
      <div class="header-title">
        <p class="print-title">한국 재활 임상평가 데이터베이스 평가 결과지</p>
        <p class="print-date">발급일자 {{ today }}</p>
      </div>
      <hr>
    </div>
    <div class="contents">
      <div class="user-info">
        <div class="info-tag">
          <img src="@/assets/images/IA/paper_icon_user.png" alt="userInfo" style="width: 18px"/>
          <p style="margin-left: 10px; white-space: nowrap;">환자 기존 정보</p>
        </div>
        <div class="info-container">
          <div class="info-label">
            <p>환자번호</p>
            <p>환자명</p>
            <p>첫 등록 날짜</p>
          </div>
          <div class="info-data">
            <p class="user-no" v-html="fbDataPatientsInfo.patientNo"></p>
            <p class="user-name" v-html="fbDataPatientsInfo.userInfo.name"></p>
            <p class="user-ad-date" v-html="fbDataPatientsInfo.regDate"></p>
          </div>
          <hr class="info-line">
          <div class="info-label">
            <p>평가 회차</p>
            <p>마지막 평가일</p>
            <p>마지막 진단명</p>
          </div>
          <div class="info-data">
            <p class="user-eval-count" v-html="fbDataPatientsInfo.visitCount + '회차'"></p>
            <p class="user-last-eval-date" v-html="fbDataPatientsInfo.lastDate"></p>
            <p class="user-last-diagnosis" v-html="fbDataPatientsInfo.diagnosis"></p>
          </div>
        </div>
      </div>
      <div class="user-evaluation" style="margin-top: 40px;">
        <div class="info-tag">
          <img src="@/assets/images/IA/paper_icon_doc.png" alt="userDoc" style="width: 18px"/>
          <p style="margin-left: 10px;white-space: nowrap;">시행 평가 정보</p>
        </div>
        <div class="info-container evaluation">
          <div class="info-label">
            <p>평가명</p>
            <p>평가 도구 설명</p>
          </div>
          <div class="info-data">
            <p class="user-eval-division" v-html="toolName"></p>
            <p class="eval-description" v-html="toolDescription">
            </p>
          </div>
        </div>
        <div class="result-evaluation white">
          <mdb-line-chart
              :data="lineChartData"
              :options="lineChartOptions"
              :width="1070"
              :height="174"
              v-if="isShowChart"
          />

          <StatisticsFm :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart && selectTool==='fm'"/>
          <StatisticsBb :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='bb'"/>
          <StatisticsSis :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='sis'"/>
          <StatisticsAaut :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='aaut'"/>
          <StatisticsMal :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='mal'"/>
          <StatisticsWmft :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='wmft'"/>
          <StatisticsBbs :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='bbs'"/>
          <StatisticsTug :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='tug'"/>
          <StatisticsFtsts :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='ftsts'"/>
          <StatisticsFm_l :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='fm_l'"/>
          <StatisticsTmwt :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='tmwt'"/>
          <StatisticsSmwt :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='smwt'"/>
          <StatisticsDgi :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='dgi'"/>
          <StatisticsAbc :selectTool="selectTool.toString()" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='abc'"/>
        </div>
        <hr>
        <div class="footer">
          <div class="contact-info">
            <p>
              <strong>한국 재활 임상평가 데이터베이스</strong><br/>
              대표자 : 김수진 ㅣ (55069) 전라북도 전주시 완산구 천잠로 303, 전주대학교 천잠관 410호<br/>
              TEL : 063-220-2226 ㅣ EMAIL : veritaspt@gmail.com<br/>
              Copyrights (C)2021 Jeonju University. All Rights Reserved.
            </p>
          </div>
          <img class="paper-footer-logo" src="@/assets/images/IA/paper_footer_logo.png" alt="footer"
               style="width: 190px"/>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button type="button" @click="printCall">프린트</button>
      <button type="button" @click="backPage">뒤로가기</button>
    </div>
  </div>
</template>

<script>
import {mdbLineChart} from "mdbvue";
import toolsDescription from "@/assets/data/evaluationToolsInfo/toolsDescription";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import StatisticsWmft from "@/views/therapist/menu/patientsManage/detail/StatisticsWmft";
import StatisticsMal from "@/views/therapist/menu/patientsManage/detail/StatisticsMal";
import StatisticsAaut from "@/views/therapist/menu/patientsManage/detail/StatisticsAaut";
import StatisticsSis from "@/views/therapist/menu/patientsManage/detail/StatisticsSis";
import StatisticsBb from "@/views/therapist/menu/patientsManage/detail/StatisticsBb";
import StatisticsFm from "@/views/therapist/menu/patientsManage/detail/StatisticsFm";
import StatisticsBbs from "@/views/therapist/menu/patientsManage/detail/StatisticsBbs";
import StatisticsTug from "@/views/therapist/menu/patientsManage/detail/StatisticsTug";
import StatisticsFtsts from "@/views/therapist/menu/patientsManage/detail/StatisticsFtsts";
import StatisticsFm_l from "@/views/therapist/menu/patientsManage/detail/StatisticsFm_l";
import StatisticsTmwt from "@/views/therapist/menu/patientsManage/detail/StatisticsTmwt";
import StatisticsSmwt from "@/views/therapist/menu/patientsManage/detail/StatisticsSmwt";
import StatisticsDgi from "@/views/therapist/menu/patientsManage/detail/StatisticsDgi";
import StatisticsAbc from "@/views/therapist/menu/patientsManage/detail/StatisticsAbc";

export default {
  name: "PdfUser",
  components: {
    StatisticsWmft,
    StatisticsMal,
    StatisticsAaut,
    StatisticsSis,
    StatisticsBb,
    StatisticsFm,
    StatisticsBbs,
    StatisticsTug,
    StatisticsFtsts,
    StatisticsFm_l,
    StatisticsTmwt,
    StatisticsSmwt,
    StatisticsDgi,
    StatisticsAbc,
    mdbLineChart,
  },
  data() {
    return {
      fbCollection: 'patients',
      fbDocId: this.$route.params.id,
      fbDataPatientsInfo: {
        userInfo: {}
      },
      fbDataUserInfo: {},
      today: '0000-00-00',
      toolName: '',
      toolDescription: '',
      toolsDescription: toolsDescription,
      data: {
        columns: [],
        rows: []
      },
      statisticsList: [],
      selectDiagnosis: this.$route.query.selectDiagnosis,
      selectPart: this.$route.query.selectPart,
      selectHand: this.$route.query.selectHand,
      selectTool: this.$route.query.selectTool,
      selectStartDate: this.$route.query.selectStartDate,
      selectEndDate: this.$route.query.selectEndDate,
      isShowChart: false,
      dataTableList: [],
      lineChartData: {
        labels: [],
        datasets: []
      },
      lineChartOptions: {
        legend: {
          fullWidth: false,
          position: 'bottom'
        },
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              }
            }
          ]
        }
      }
    }
  },
  async mounted() {
    this.setToday();
    await this.getDataPatientInfo();
    await this.setDescription();
    await this.searchStatistics();
  },
  methods: {

    setToday() {
      const self = this;
      let d = new Date();
      self.today = d.getFullYear()
          + "-" + ("00" + (d.getMonth() + 1)).slice(-2)
          + "-" + ("00" + d.getDate()).slice(-2)
          + " " + ("00" + d.getHours()).slice(-2)
          + ":" + ("00" + d.getMinutes()).slice(-2)
          + ":" + ("00" + d.getSeconds()).slice(-2)
    },

    async getDataPatientInfo() {
      const self = this;
      await firebase.firestore().collection(self.fbCollection)
          .doc(self.fbDocId).get().then(async (querySnapshot) => {
            const _data = querySnapshot.data();
            if (_data != null) {
              const date = new Date(_data.regDate.seconds * 1000);
              _data.regDate = getDate(date);
              const lastDate = new Date(_data.lastDate.seconds * 1000);
              _data.lastDate = getDate(lastDate);
              self.fbDataPatientsInfo = _data
            }
          });
    },
    setDescription() {
      const self = this;
      switch (self.$route.query.selectTool) {
        case 'fm':
          self.toolName = '전반적 상지 손상 검사(FM)'
          self.toolDescription = self.toolsDescription.fm
          return
        case 'bb':
          self.toolName = '손재주 검사 - 블록 옮기기(BB)'
          self.toolDescription = self.toolsDescription.bb
          return
        case 'sis':
          self.toolName = '삶의 질 검사 - 우울증 검사(SIS)'
          self.toolDescription = self.toolsDescription.sis
          return
        case 'aaut':
          self.toolName = '일상 기능 검사(AAUT)'
          self.toolDescription = self.toolsDescription.aaut
          return
        case 'mal':
          self.toolName = '상지 사용 능력 검사(MAL)'
          self.toolDescription = self.toolsDescription.mal
          return
        case 'wmft':
          self.toolName = '상지 운동 검사(WMFT)'
          self.toolDescription = self.toolsDescription.wmft
          return
        case 'bbs':
          self.toolName = '버그 균형 척도(BBS)'
          self.toolDescription = self.toolsDescription.bbs
          return
        case 'tug':
          self.toolName = '이동성 검사(TUG)'
          self.toolDescription = self.toolsDescription.tug
          return
        case 'ftsts':
          self.toolName = '근력/균형조절 검사-앉았다 일어나기(FTSTS)'
          self.toolDescription = self.toolsDescription.ftsts
          return
        case 'fm_l':
          self.toolName = '전반적 하지 손상 검사(FM)'
          self.toolDescription = self.toolsDescription.fm_l
          return
        case 'tmwt':
          self.toolName = '10m 걷기 검사(10MWT)'
          self.toolDescription = self.toolsDescription.tmwt
          return
        case 'smwt':
          self.toolName = '6분 걷기 검사(6MWT)'
          self.toolDescription = self.toolsDescription.smwt
          return
        case 'dgi':
          self.toolName = '동적 보행 검사(DGI)'
          self.toolDescription = self.toolsDescription.dgi
          return
        case 'abc':
          self.toolName = '활동 특이적 균형 자신감 척도(ABC Scales)'
          self.toolDescription = self.toolsDescription.abc
          return
      }
    },
    searchStatistics() {
      const self = this;
      self.resetChartData();
      self.isShowChart = false;

      let docBasic = firebase.firestore().collection('evaluations')
          .where('patientInfo.uid', '==', self.fbDataPatientsInfo.userInfo.uid)
          .where('state', '==', 1);


      if (self.selectTool !== '') {
        if (self.selectTool === 'fm') {
          docBasic = docBasic
              .where('tools.fm.state', '==', 2)
        } else if (self.selectTool === 'wmft') {
          docBasic = docBasic
              .where('tools.wmft.state', '==', 2)
        } else if (self.selectTool === 'sis') {
          docBasic = docBasic
              .where('tools.sis.state', '==', 2)
        } else if (self.selectTool === 'mal') {
          docBasic = docBasic
              .where('tools.mal.state', '==', 2)
        } else if (self.selectTool === 'bb') {
          docBasic = docBasic
              .where('tools.bb.state', '==', 2)
        } else if (self.selectTool === 'aaut') {
          docBasic = docBasic
              .where('tools.aaut.state', '==', 2)
        } else if (self.selectTool === 'bbs') {
          docBasic = docBasic
              .where('tools.bbs.state', '==', 2)
        } else if (self.selectTool === 'tug') {
          docBasic = docBasic
              .where('tools.tug.state', '==', 2)
        } else if (self.selectTool === 'ftsts') {
          docBasic = docBasic
              .where('tools.ftsts.state', '==', 2)
        } else if (self.selectTool === 'fm_l') {
          docBasic = docBasic
              .where('tools.fm_l.state', '==', 2)
        } else if (self.selectTool === 'tmwt') {
          docBasic = docBasic
              .where('tools.tmwt.state', '==', 2)
        } else if (self.selectTool === 'smwt') {
          docBasic = docBasic
              .where('tools.smwt.state', '==', 2)
        } else if (self.selectTool === 'dgi') {
          docBasic = docBasic
              .where('tools.dgi.state', '==', 2)
        } else if (self.selectTool === 'abc') {
          docBasic = docBasic
              .where('tools.abc.state', '==', 2)
        }
      }

      self.realQuery(docBasic)
    },
    realQuery(doc) {
      const self = this;
      self.dataTableList.splice(0)
      let fmLineData = {
        label: self.selectTool,
        backgroundColor: "rgba(36, 194, 153, 0.1)",
        borderColor: "rgba(36, 194, 153, 0.6)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData2 = {
        label: self.selectTool,
        backgroundColor: "rgba(255, 99, 132, 0.1)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData3 = {
        label: self.selectTool,
        backgroundColor: "rgba(151,187,205,0.2)",
        borderColor: "rgba(151,187,205,1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData4 = {
        label: self.selectTool,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }


      doc.orderBy('regDate', 'asc')
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          self.statistics = '데이터가 없습니다.'
          return
        }


        querySnapshot.forEach((doc) => {
          const _data = doc.data();
          const date = new Date(_data.regDate.seconds * 1000);
          _data.regDate = getDate(date);
          // 챠트라벨넣기
          self.lineChartData.labels.push(getDate(date));
          if (self.selectTool === 'fm') {
            fmLineData.data.push(_data.tools.fm.totalAll);
          } else if (self.selectTool === 'bb') {
            fmLineData.data.push(_data.tools.bb.la)
            fmLineData2.data.push(_data.tools.bb.ma)
          } else if (self.selectTool === 'sis') {
            fmLineData.data.push(_data.tools.sis.totalAvg)
            fmLineData2.data.push(_data.tools.sis.totalSum)
          } else if (self.selectTool === 'aaut') {
            fmLineData.data.push(_data.tools.aaut.totalAouScore)
            fmLineData2.data.push(_data.tools.aaut.totalQomScore)
            // fmLineData3.data.push(_data.tools.aaut.totalAouAvg)
            // fmLineData4.data.push(_data.tools.aaut.totalQomAvg)
          } else if (self.selectTool === 'mal') {
            fmLineData.data.push(_data.tools.mal.totalAouScore)
            fmLineData2.data.push(_data.tools.mal.totalQomScore)
            // fmLineData3.data.push(_data.tools.mal.totalAouAvg)
            // fmLineData4.data.push(_data.tools.mal.totalQomAvg)
          } else if (self.selectTool === 'wmft') {
            fmLineData.data.push(_data.tools.wmft.totalAvg)
          } else if (self.selectTool === 'bbs') {
            fmLineData.data.push(_data.tools.bbs.total)
          } else if (self.selectTool === 'tug') {
            fmLineData.data.push(_data.tools.tug.time)
          } else if (self.selectTool === 'ftsts') {
            fmLineData.data.push(_data.tools.ftsts.time)
          } else if (self.selectTool === 'fm_l') {
            fmLineData.data.push(_data.tools.fm_l.totalAll)
          } else if (self.selectTool === 'tmwt') {

            _data.tools.tmwt['average_self_selected_speed'] = _data.tools.tmwt.self_selected_speed.average_self_selected
            _data.tools.tmwt['average_fast_speed'] = _data.tools.tmwt.fast_speed.average_self_selected
            fmLineData.data.push(_data.tools.tmwt.average_self_selected_speed)
            fmLineData2.data.push(_data.tools.tmwt.average_fast_speed)
          } else if (self.selectTool === 'smwt') {

            _data.tools.smwt['total'] =
                _data.tools.smwt.distance_1 + _data.tools.smwt.distance_2 + _data.tools.smwt.distance_3 +
                _data.tools.smwt.distance_4 + _data.tools.smwt.distance_5 + _data.tools.smwt.distance_6
            fmLineData.data.push(_data.tools.smwt.total)
          } else if (self.selectTool === 'dgi') {
            fmLineData.data.push(_data.tools.dgi.total)
          } else if (self.selectTool === 'abc') {
            fmLineData.data.push(_data.tools.abc.total)
          }

          self.statisticsList.push(_data);
          self.dataTableList.push(_data);
        });

        self.setLabel(fmLineData, fmLineData2, fmLineData3, fmLineData4);
        self.isShowChart = true;
      });

    },

    setLabel(fmLineData, fmLineData2, fmLineData3, fmLineData4) {
      const self = this;
      switch (self.selectTool) {
        case 'fm':
          fmLineData.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'bb':
          fmLineData.label = 'Less-Affected Hand'
          fmLineData2.label = 'More-Affected Hand'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'sis':
          fmLineData.label = '평균'
          fmLineData2.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'aaut':
        case 'mal':
          fmLineData.label = 'AOU Score'
          fmLineData2.label = 'QOM Score'
          fmLineData3.label = 'AOU AVG'
          fmLineData4.label = 'QOM AVG'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          // self.lineChartData.datasets.push(fmLineData3)
          // self.lineChartData.datasets.push(fmLineData4)
          return
        case 'wmft':
          fmLineData.label = '평균'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'bbs':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'tug':
          fmLineData.label = '시간(초)'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'ftsts':
          fmLineData.label = '시간(초)'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'fm_l':
          fmLineData.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'tmwt':
          fmLineData.label = 'self selected speed'
          fmLineData2.label = 'fast speed'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'smwt':
          fmLineData.label = '총 거리'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'dgi':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'abc':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
      }
    },

    resetChartData() {
      const self = this;
      self.lineChartData.datasets = [];
      self.lineChartData.labels = [];
      self.statisticsList = [];
    },

    printCall() {
      window.print()
    },

    backPage() {
      this.$router.go(-1);
    }
  }


}
</script>

<style scoped>

.main-container {
  width: 1160px;
  background: #fff;
  padding: 0 30px;
  margin: 0 auto;
}

.header {
  padding-top: 120px;
}

.header-title {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000;
  margin-top: 20px;
  position: relative;
}

.print-date {
  font-weight: 400;
  font-size: 16px;
  color: #000;
  line-height: 32px;
  text-align: right;
  position: absolute;
  right: 0;
}

hr {
  background: #000;
  width: 100%;
  height: 2px;
  margin: 30px 0 40px 0;
}

.info-tag {
  width: 154px;
  height: 28px;
  background: #0A1120;
  padding: 0 15px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.info-container {
  width: 100%;
  padding: 23px 50px;
  background: #F8F8FA;
  border: 1px solid #E1E3E6;
  color: #000;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 400;
}

.part-container {
  color: #000;
  font-size: 16px;
  line-height: 28px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 400;
}

.result-evaluation {
  width: 100%;
  padding: 23px 20px;
  background: #fff;
  border: 1px solid #E1E3E6;
  border-radius: 8px;
}

.evaluation {
  align-items: baseline;
}

.info-label > p {
  width: 100px;
  margin-right: 20px;
  font-weight: 400;
  color: #8d8d8d;
  font-size: 16px;
  line-height: 28px;
}

.info-line {
  width: 1px;
  height: 100px;
  background: #e1e3e6;
  margin: 0 60px;
}

.user-eval-division {
  font-weight: 700;
  font-size: 16px;
  color: #0069CA;
  line-height: 28px;
  margin-bottom: 4px;
}

.eval-description {
  line-height: 22px;
  font-weight: 300;
}

.total-average {
  font-weight: 700;
}

.total-average strong {
  font-weight: 700;
  color: #0069CA !important;
}

.white {
  background: #fff;
  margin-top: 10px;
}

.footer {
  width: 100%;
  display: flex;
  align-items: baseline;
  position: relative;
  color: #000;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 20px;
}

.paper-footer-logo {
  position: absolute;
  right: 0;
}

/deep/ .mdb-datatable-footer {
  display: none;
}

.btn-container {
  position: absolute;
  top: 30px;
  right: 30px;
}

.btn-container button {
  margin: 3px;
}
</style>
